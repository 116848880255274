const resource = {
    en: {
        heading: 'Confirm Leaving',
        text: 'Please confirm that you want to leave the learning class.',
        confirm: 'Confirm',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Verlassen Bestätign',
        text: 'Bitte bestätigen Sie, dass Sie die Lernklasse verlassen möchten.',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen'
    }
}

export default resource;