<template>
    <div class="wrapper">
        <form id="learning-class-form" @submit.prevent="handleSubmit">
            <InputText :label="resource[lang].name" v-model="name" />
            <InputTextArea :label="resource[lang].description" v-model="description" />
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/learning-class-form-resource'

import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { getAbility } from '@/assets/js/user-abilities'
import { promisePostRequest, promisePutRequest} from '@/assets/js/axios-utilities'

export default {
    props: {
        learning_class: Object
    },
    components: {
        InputText,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            // input values
            name: '',
            description: '',

            // user abilities
            create: false,
            update: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        learning_class_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            if (this.learning_class) {
                this.name = this.learning_class.name;
                this.description = this.learning_class.description;
            }
            this.getUserAbilities();
        },
        async getUserAbilities() {
            if (this.learning_class) {
                this.update = await getAbility('update-learning-class', this.user, this.learning_class);
            } else {
                this.create = await getAbility('create-learning-class', this.user, this.learning_class);
            }
        },

        // Requests
        createLearningClassRequest() {
            const request_body = {
                name: this.name,
                description: this.description,
            }
            return promisePostRequest('/learning-class', request_body);
        },
        updateLearningClassRequest() {
            const request_body = {
                name: this.name,
                description: this.description,
            }
            return promisePutRequest(`/learning-class/${this.learning_class_id}`, request_body);
        },

        // Actions
        submit() {
            const input_items = document.querySelectorAll("input");

            let valid = true;
            for (let item of input_items) {
                if(!item.checkValidity()) {
                    valid = false;
                }
            }

            document.getElementById('learning-class-form').classList.add('submitted'); 

            if (valid) {
                this.handleSubmit();
            } else {
                window.scrollTo(0, 0);
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].fill_fields,
                    color: 'negative'
                });
            }
        },
        async handleSubmit() {
            if (this.learning_class) {
                this.$store.dispatch('loading', true);
                await this.updateLearningClassRequest();
                this.$store.dispatch('loading', false);

                this.$emit('reload');

                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].updated,
                    color: 'positive'
                });
            } else {
                this.$store.dispatch('loading', true);
                const response = await this.createLearningClassRequest();
                this.$store.dispatch('loading', false);

                this.$router.push(`/dashboard/learning-class/${response.data.learning_class.id}`);

                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].updated,
                    color: 'positive'
                });
            }
        },
    },
    watch: {
        user: function() {
            this.getUserAbilities();
        },
        learning_class: function() {
            this.init();
        }
    },
    emits: ['reload']
}
</script>

<style lang="scss" scoped>

form > * + * {
    margin-block-start: 1rem;
}

</style>