<template>
    <div class="wrapper" v-if="learning_class">

        <h2 class="basic-form-heading-1" v-html="resource[lang].heading" />

        <h3 class="basic-form-heading-2" v-html="resource[lang].teachers" />

        <UserInfoCard 
            class="user"
            v-for="teacher in learning_class.teachers" 
            :key="teacher.id" 
            :user="teacher"
            :show_matrices="teacher.id != user.id"
            @show_matrices="showMatricesOfUserModal(teacher.id)"
            :remove="update == true"
            @remove="showRemoveUserModal(teacher.id)"
        />

        <div v-if="learning_class.teachers.length === 0" class="not-found">
            <p class="text" v-html="resource[lang].no_users" />
        </div>

        <h3 class="basic-form-heading-2" v-html="resource[lang].members" />

        <UserInfoCard 
            class="user"
            v-for="member in learning_class.members" 
            :key="member.id" 
            :user="member"
            :show_matrices="member.id != user.id"
            @show_matrices="showMatricesOfUserModal(member.id)"
            :remove="update == true"
            @remove="showRemoveUserModal(member.id)"
        />
        <div v-if="learning_class.members.length === 0" class="not-found">
            <p class="text" v-html="resource[lang].no_users" />
        </div>
    </div>

    <MatricesOfUserModal
        v-if="showMatricesOfUserModalFlag"
        :user_id="shared_user_id"
        @cancel="cancelShowMatricesOfUser"
    />

    <RemoveUserModal 
        v-if="showRemoveUserModalFlag"
        @confirm="removeUser"
        @cancel="cancelRemoveUser"
    />

</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/learning-class-users-resource'

import MatricesOfUserModal from './modals/MatricesOfUserModal.vue'
import RemoveUserModal from './modals/RemoveUserModal.vue'
import UserInfoCard from '@/components/UserInfoCard'

import { promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    props: {
        learning_class: Object
    },
    components: {
        MatricesOfUserModal,
        RemoveUserModal,
        UserInfoCard,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            showRemoveUserModalFlag: false,
            remove_user_id: null,

            showMatricesOfUserModalFlag: false,
            shared_user_id: null,

            // user abilities
            update: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    created() {
        this.getAbilities();
    },
    methods: {
        // Init
        async getAbilities() {
            this.update = await getAbility('update-learning-class', this.user, this.learning_class);
        },

        // Requests
        removeUserRequest() {
            const request_body = {
                user_id: this.remove_user_id
            }
            return promisePutRequest(`remove-learning-class-user/${this.learning_class.id}`, request_body)
        },

        // Actions
        showRemoveUserModal(id) {
            if (this.update) {
                this.remove_user_id = id
                this.showRemoveUserModalFlag = true
                this.$store.dispatch('modal', true)
            }
        },
        cancelRemoveUser() {
            this.remove_user_id = null
            this.showRemoveUserModalFlag = false
        },
        async removeUser() {
            this.$store.dispatch('loading', true)
            await this.removeUserRequest()
            this.$store.dispatch('loading', false)

            this.remove_user_id = null
            this.showRemoveUserModalFlag = false
            this.$emit('reload');
        },
        showMatricesOfUserModal(user_id) {
            this.shared_user_id = user_id
            this.showMatricesOfUserModalFlag = true
            this.$store.dispatch('modal', true)
        },
        cancelShowMatricesOfUser() {
            this.shared_user_id = null
            this.showMatricesOfUserModalFlag = false
        },
    },
    watch: {
        learning_class: function() {
            this.getAbilities();
        },
        user: function() {
            this.getAbilities();
        }
    },
    emits: ['reload']
}
</script>

<style lang="scss" scoped>

.user + .user {
    margin-block-start: 0.5rem;
}

.wrapper {
    margin-block-start: 2rem;
}

</style>