const resource = {
    en: {
        heading: 'Users in Learning Class',
        teachers: 'Teachers',
        members: 'Members',
        no_users: 'No Users'
    },
    de: {
        heading: 'Anwender*innen in der Lernklasse',
        teachers: 'Lehrer*innen',
        members: 'Mitglieder',
        no_users: 'Kein Anwender*innen'
    }
}

export default resource;