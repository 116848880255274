const resource = {
    en: {
        heading: 'Remove User',
        text: 'Please confirm that you want to remove the selected user from your learning class.',
        confirm: 'Confirm',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Benutzer*in entfernen',
        text: 'Bitte bestätigen Sie, dass Sie die Person aus Ihrer Lernklasse entfernen möchten.',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen'
    }
}

export default resource;