const resource = {
    en: {
        name: 'Name of Learning Class',
        description: 'Description',
        updated: 'The Learning Class was updated',
        fill_fields: 'Please fill in the required field'
    },
    de: {
        name: 'Name der Lernklasse',
        description: 'Beschreibung',
        updated: 'Die Änderungen wurden übernommen',
        fill_fields: 'Bitte füllen Sie das benötigte Feld aus'
    }
}

export default resource;