const resource = {
    en: {
        heading: 'Add User to Learning Class',
        selected: 'Selected Users',
        users: 'Users',
        show_more: 'show more',
        add_as_teacher: 'Add as teacher',
        add_as_member: 'Add as member',
        enter_search_term: 'Enter search term...',
        search: 'Search',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Anwender*innen hinzufügen',
        selected: 'Ausgewählte Anwender*innen',
        users: 'Anwender*innen',
        show_more: 'mehr anzeigen',
        add_as_teacher: 'Als Lehrer*in hinzufügen',
        add_as_member: 'Als Mitglied hinzufügen',
        enter_search_term: 'Suchwort eingeben...',
        search: 'Suchen',
        cancel: 'Abbrechen'
    }
}

export default resource;