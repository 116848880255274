const resource = {
    en: {
        commented_on: 'commented on',
        delete: 'delete',
        edit: 'edit',
        updated_message: 'Updated Message',
        update: 'Update',
        discard: 'Discard',
        only_for_teachers: 'Only visible for Teachers',
        important: 'Important'
    },
    de: {
        commented_on: 'kommentiert am',
        delete: 'löschen',
        edit: 'bearbeiten',
        updated_message: 'Neuer Nachricht',
        update: 'Änderung Bestätigen',
        discard: 'Änderung Verwerfen',
        only_for_teachers: 'Only visible for Teachers',
        important: 'Important'
    }
}

export default resource;