<template>
    <BaseModal ref="base_modal" @close="cancel">
        <label class="modal-heading" v-html="resource[lang].heading" />
        <ul class="matrix-list" v-if="shared_matrices.length > 0">
            <router-link
                v-for="matrix in shared_matrices" 
                :key="matrix.id" 
                :to="`/matrix/${matrix.id}/`"
            >
                <li v-html="matrix.name" @click="cancel" />
            </router-link>
        </ul>

        <p v-else v-html="resource[lang].no_matrices" />
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/matrices-of-user-modal-resource'

import BaseModal from '@/components/modals/BaseModal'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        BaseModal
    },
    props: {
        user_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            shared_matrices: [],

            resource
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        // init
        async init() {
            this.$store.dispatch('loading', true)
            const response = await this.getSharedMatricesRequest();
            this.$store.dispatch('loading', false)

            this.shared_matrices = response.data;
        },

        // Requests
        getSharedMatricesRequest() {
            return promiseGetRequest(`my-shared-matrices-of-user/${this.user_id}`)
        },

        // Actions
        cancel() {
            this.$refs.base_modal.closeModal();
            this.$emit('cancel');
        }
    },
    watch: {
        user_id: function(val){
            console.log('userid was updated')
            console.log(val)
        }
    },
    emits: ['cancel']
}
</script>

<style lang="scss" scoped>

.matrix-list {
    list-style: none;

    li {
        cursor: pointer;

        background-color: var(--whit);
        color: var(--primary);

        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 500;

        text-transform: uppercase;

        padding: 2rem;

        margin-block-start: 0.5rem;
    }

    li:hover, li:focus-visible {
        color: var(--blak);
        border: solid 1px var(--blak);
        margin-block-end: -2px;
    }
}

</style>