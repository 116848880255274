<template>
    <div class="wrapper">
        <div class="dashboard-main">
            <h1 class="dashboard-main-heading" v-html="learning_class ? learning_class.name : 'Create New Learning Class'" />

            <div class="actions" v-if="learning_class">
                <button v-if="update && mode == 'main'" v-html="resource[lang].edit" @click="triggerEdit" />
                <button v-if="delet && mode == 'form'" v-html="resource[lang].delete" @click="showDeleteModal" />
                <button v-if="mode == 'form'" v-html="resource[lang].back_to_learning_class" @click="triggerMain" />
                <button v-if="mode == 'main' && user && user.id != learning_class.user_id" v-html="resource[lang].leave_learning_class" @click="showLeaveLearningClassModal" />
            </div>

            <LearningClassUsers
                v-if="mode === 'main'"
                :learning_class="learning_class"
                @reload="getLearningClass"
            />

            <LearningClassMain 
                v-if="learning_class && mode === 'main'" 
                :learning_class="learning_class" 
                @reload="getLearningClass" 
            />

            <LearningClassForm 
                v-if="mode === 'form'" 
                ref="learning_class_form" 
                :learning_class="learning_class" 
                @reload="getLearningClass" 
            />

            <LearningClassUsers
                v-if="mode === 'form'"
                :learning_class="learning_class"
                @reload="getLearningClass"
            />

        </div>

        <div class="flex">
            <div>
                <Button v-if="mode === 'form'" color="accent" :label="resource[lang].save" @click="submitForm" />
                <Button v-if="mode === 'form' && learning_class_id" color="secondary" :label="resource[lang].add_user" @click="showAddUserModal" />
            </div>
            <div>
                <Button v-if="mode === 'form'" color="secondary" :label="resource[lang].discard" @click="discardForm" />
            </div>
        </div>

        <DeleteModal
            v-if="showDeleteModalFlag"
            @confirm="deleteLearningClass"
            @cancel="cancelDeleteModal"
        />

        <LeaveLearningClassModal
            v-if="showLeaveLearningClassModalFlag"
            @confirm="leaveLearningClass"
            @cancel="cancelLeaveLearningClassModal"
        />

        <AddUserModal
            v-if="showAddUserModalFlag"
            :learning_class="learning_class"
            @add="addUsers"
            @cancel="cancelAddUserModal"
        />
        
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/learning-class-resource'

import AddUserModal from '@/components/modals/AddUserModal'
import Button from '@/components/basic/Button'
import DeleteModal from '@/components/modals/DeleteModal'
import LearningClassForm from '@/components/forms/LearningClassForm'
import LearningClassMain from '@/components/LearningClassMain'
import LearningClassUsers from '@/components/LearningClassUsers'
import LeaveLearningClassModal from '@/components/modals/LeaveLearningClassModal'

import { promiseDeleteRequest, promiseGetRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    components: {
        AddUserModal,
        Button,
        DeleteModal,
        LearningClassForm,
        LearningClassMain,
        LearningClassUsers,
        LeaveLearningClassModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            learning_class: null,

            showAddUserModalFlag: false,
            showDeleteModalFlag: false,
            showLeaveLearningClassModalFlag: false,
            
            // user abilities
            update: false,
            delet: false,
            create_teacher_message: false,

            // mode
            mode: 'main',

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        date() {
            return new Date(this.learning_class.created_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
        learning_class_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            if (this.learning_class_id) {
                this.$store.dispatch('loading', true);
                this.getLearningClass();
                this.$store.dispatch('loading', false);
                this.getAbilities();
            } else {
                this.mode = 'form';
            }
        },
        async getAbilities() {
            this.update = await getAbility('update-learning-class', this.user, this.learning_class)
            this.delet = await getAbility('delete-learning-class', this.user, this.learning_class)
            this.create_teacher_message = await getAbility('create-learning-class-teacher-message', this.user, this.learning_class)
        },

        // Requests
        getLearningClassRequest() {
            return promiseGetRequest(`learning-class/${this.learning_class_id}`);
        },
        deleteLearningClassRequest() {
            return promiseDeleteRequest(`learning-class/${this.learning_class_id}`);
        },
        leaveLearningClassRequest() {
            return promisePutRequest(`remove-me-from-learning-class/${this.learning_class_id}`);
        },
        addUsersRequest(request_body) {
            return promisePutRequest(`add-learning-class-users/${this.learning_class_id}`, request_body)
        },

        // Actions
        submitForm() {
            this.$refs.learning_class_form.submit();
        },
        discardForm() {
            if (this.learning_class) {
                this.mode = 'main';
            } else {
                this.$router.push('/dashboard/my-matrices');
            }
        },
        triggerEdit() {
            this.mode = 'form';
        },
        triggerMain() {
            this.mode = 'main';
        },
        showDeleteModal() {
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelDeleteModal() {
            this.showDeleteModalFlag = false;
        },
        showLeaveLearningClassModal() {
            this.showLeaveLearningClassModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelLeaveLearningClassModal() {
            this.showLeaveLearningClassModalFlag = false;
        },
        showAddUserModal() {
            this.showAddUserModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelAddUserModal() {
            this.showAddUserModalFlag = false;
        },
        deleteLearningClass() {
            this.showDeleteModalFlag = false;
            
            this.$store.dispatch('loading', true);
            this.deleteLearningClassRequest();
            this.$store.dispatch('loading', false);

            this.$router.push('/dashboard/my-matrices');
        },
        leaveLearningClass() {
            this.$store.dispatch('loading', true);
            this.leaveLearningClassRequest();
            this.$store.dispatch('loading', false);

            this.$router.push('/dashboard/my-matrices');
        },
        addUsers(user_ids, teacher) {
            const request_body = {
                user_ids: user_ids,
                teacher: teacher
            }
            
            this.$store.dispatch('loading', true);
            this.addUsersRequest(request_body);
            this.getLearningClass();
            this.$store.dispatch('loading', false);

            this.showAddUserModalFlag = false;
        },

        // Functions
        async getLearningClass() {
            if (this.$route.fullPath.includes('learning-class')) {
                const response = await this.getLearningClassRequest();
                this.learning_class = response.data;
            }
        },
    },
    watch: {
        user: function() {
            this.getAbilities();
        },
        learning_class_id: function() {
            this.init();
        },
        learning_class: function() {
            this.getAbilities();
        }
    }
}
</script>

<style lang="scss" scoped>

.actions {
    position: absolute;
    top: 2rem;
    right: 2rem;

    button {
        cursor: pointer;

        background-color: var(--light_grey);
        border: none;
        color: var(--accent);

        font-family: var(--standard-font);
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        
        text-decoration: underline;

        &:hover, &:focus-visible {
            font-weight: 400;
        }
    }

    button + button {
        margin-inline-start: 1.5rem;
    }
}

.dashboard-main-heading {
    max-width: calc(100% - 22rem);
}

.flex {
    justify-content: space-between;

    margin-block-start: 1rem;

    button + button {
        margin-inline-start: 1rem;
    }
}

@media (max-width: 65rem) {
    .actions {
        top: 1rem;
    }
}

@media (max-width: 40rem) {
    .actions {
        position: relative;

        top: 0;
        left: 0;

        margin-block-end: 2rem;
    }

    .dashboard-main-heading {
        max-width: 100%;
    }

    .flex {
        display: block;

        button {
            width: 100%;
        }

        button + button {
            margin: 0;
            margin-block-start: 1rem;
            margin-block-end: 1rem;
        }
    }
}

</style>