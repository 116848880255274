const resource = {
    en: {
        heading: 'Shared Matrices',
        no_matrices: 'There are no shared matrices available'
    },
    de: {
        heading: 'Geteilte Matrizen',
        no_matrices: 'Diese*r Anwender*in hat keine Matrizen mit Ihnen geteilt'
    }
}

export default resource;