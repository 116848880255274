<template>
    <BaseModal ref="base_modal" @close="cancel">
        <label class="modal-heading" v-html="resource[lang].heading" />
        <p class="modal-text center" v-html="resource[lang].text" />
        <div class="actions center">
            <Button color="accent" :label="resource[lang].confirm" @click="confirm" />
            <Button color="white" :label="resource[lang].cancel" @click="cancel" />
        </div>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/remove-user-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'

export default {
    components: {
        BaseModal,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    methods: {
        confirm() {
            this.$refs.base_modal.closeModal();
            this.$emit('confirm');
        },
        cancel() {
            this.$refs.base_modal.closeModal();
            this.$emit('cancel');
        }
    },
    emits: ['confirm', 'cancel']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;
}

.actions > * + * {
    margin-inline-start: 1rem;
}

@media (max-width: 40rem) {
    .actions > * {
        width: 100% !important;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }
}

</style>