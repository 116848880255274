<template>
    <div v-if="learning_class">
        
        <!-- DESCRIPTION -->
        <h2 class="basic-form-heading-2" v-html="resource[lang].description" />
        <div class="description">
            <p class="details" v-html="`${resource[lang].created_on} ${date} ${resource[lang].by} ${learning_class.owner.full_name}`" />
            <p class="comment-text" v-html="learning_class.description" />
        </div>

        <!-- MEMBER AREA  -->
        <div v-if="!teacher_area" class="member-area">

            <button 
                v-if="create_teacher_message" 
                class="switch-button" 
                v-html="resource[lang].show_teacher_messages"
                @click="teacher_area = true"
            />

            <h2 class="basic-form-heading-2" v-html="resource[lang].create_form.heading" />

            <InputTextArea :label="resource[lang].create_form.input_label" v-model="new_member_message" />

            
            <div class="button-container flex">
                <q-toggle
                    v-model="new_member_message_important"
                    color="primary"
                    keep-color
                    :label="resource[lang].create_form.mark_as_important"
                />
                <Button color="primary" :label="resource[lang].create_form.create" @click="createMemberMessage" />
            </div>

            <h2 class="basic-form-heading-2" v-html="resource[lang].messages" />

            <div class="not-found" v-if="member_messages.length == 0">
                <p class="text" v-html="resource[lang].no_messages" />
            </div>

            <LearningClassMessage 
                class="message"
                v-for="message in member_messages"
                :key="message.id"
                :message="message" 
                @updateMessage="updateLearningClassMessage"
                @deleteMessage="deleteLearningClassMessage"
            />
            <Button 
                v-if="!all_member_messages" 
                class="show-older" 
                color="primary" 
                :label="resource[lang].show_older_messages" 
                @click="loadMoreMemberMessages"
            />

        </div>

        <!-- TEACHER AREA -->
        <div v-if="teacher_area" class="teacher-area">

            <button 
                v-if="create_teacher_message" 
                class="switch-button" 
                v-html="resource[lang].show_member_messages"
                @click="teacher_area = false"
            />

            <h2 class="basic-form-heading-2" v-html="resource[lang].create_form.heading" />

            <InputTextArea :label="resource[lang].create_form.input_label" v-model="new_teacher_message" />

            <div class="button-container flex">
                <q-toggle
                    v-model="new_teacher_message_important"
                    color="primary"
                    keep-color
                    :label="resource[lang].create_form.mark_as_important"
                />
                <Button color="primary" :label="resource[lang].create_form.create" @click="createTeacherMessage" />
            </div>

            <h2 class="basic-form-heading-2" v-html="resource[lang].messages" />

            <div class="not-found" v-if="teacher_messages.length == 0">
                <p class="text" v-html="resource[lang].no_messages" />
            </div>

            <LearningClassMessage 
                class="message"
                v-for="message in teacher_messages"
                :key="message.id"
                :message="message" 
                @updateMessage="updateLearningClassMessage"
                @deleteMessage="deleteLearningClassMessage"
            />
            <Button 
                v-if="!all_teacher_messages" 
                class="show-older" 
                color="primary" 
                :label="resource[lang].show_older_messages" 
                @click="loadMoreTeacherMessages"
            />

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/learning-class-main-resource'

import Button from '@/components/basic/Button'
import InputTextArea from '@/components/basic/InputTextArea'
import LearningClassMessage from '@/components/LearningClassMessage'

import { promiseDeleteRequest, promisePostRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    inject: ['$dateOptions'],
    props: {
        learning_class: {
            type: Object,
            required: true
        }
    },
    components: {
        Button,
        InputTextArea,
        LearningClassMessage
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            // input variable
            new_member_message: '',
            new_teacher_message: '',
            new_member_message_important: false,
            new_teacher_message_important: false,

            // messages
            member_messages: [],
            member_message_page: 1,
            all_member_messages: false,

            teacher_messages: [],
            teacher_message_page: 1,
            all_teacher_messages: false,
            
            // user abilities
            update: false,
            delete: false,
            create_teacher_message: false,

            // flags
            teacher_area: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        date() {
            return new Date(this.learning_class.created_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
        learning_class_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        async init() {
            this.loadMemberMessages();
            this.getAbilities();

            if (this.create_teacher_message) {
                this.loadTeacherMessages();
            }
        },
        async getAbilities() {
            this.update = await getAbility('update-learning-class', this.user, this.learning_class)
            this.delete = await getAbility('delete-learning-class', this.user, this.learning_class)
            this.create_teacher_message = await getAbility('create-learning-class-teacher-message', this.user, this.learning_class)
        },

        // Requests
        createLearningClassMessageRequest(request_body) {
            return promisePostRequest(`learning-class-message`, request_body);
        },
        updateLearningClassMessageRequest(id, request_body) {
            return promisePutRequest(`learning-class-message/${id}`, request_body);
        },
        deleteLearningClassMessageRequest(id) {
            return promiseDeleteRequest(`learning-class-message/${id}`);
        },

        // Actions
        loadMoreMemberMessages() {
            this.member_message_page += 1;
            this.loadMemberMessages();
        },
        loadMoreTeacherMessages() {
            this.teacher_message_page += 1;
            this.loadTeacherMessages();
        },
        async updateLearningClassMessage(id, message) {
            if (this.teacher_area) {
                const index = this.teacher_messages.findIndex(message => message.id == id);
                this.teacher_messages[index].message = message;

                this.$store.dispatch('loading', true)
                await this.updateLearningClassMessageRequest(id, this.teacher_messages[index]);
                this.$store.dispatch('loading', false)
            } else {
                const index = this.member_messages.findIndex(message => message.id == id);
                this.member_messages[index].message = message;

                this.$store.dispatch('loading', true)
                await this.updateLearningClassMessageRequest(id, this.member_messages[index]);
                this.$store.dispatch('loading', false)
            }
        },
        async deleteLearningClassMessage(id) {
            if (this.teacher_area) {
                const index = this.teacher_messages.findIndex(message => message.id == id);

                this.teacher_messages.splice(index, 1);

                this.$store.dispatch('loading', true)
                await this.deleteLearningClassMessageRequest(id);
                this.$store.dispatch('loading', false)
            } else {
                const index = this.member_messages.findIndex(message => message.id == id);

                this.member_messages.splice(index, 1);

                this.$store.dispatch('loading', true)
                await this.deleteLearningClassMessageRequest(id);
                this.$store.dispatch('loading', false)
            }  
        },
        async createMemberMessage() {
            const request_body = {
                message: this.new_member_message,
                important: this.new_member_message_important,
                teacher: false,
                learning_class_id: this.learning_class_id,
            }

            this.$store.dispatch('loading', true);
            const response = await this.createLearningClassMessageRequest(request_body);
            this.$store.dispatch('loading', false);

            this.member_messages.push(response.data.learning_class_message);

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].create_form.success, 
                color: 'positive'
            });

            this.new_member_message = '';
            this.new_member_message_important = false;

            this.$emit('reload');
        },
        async createTeacherMessage() {
            const request_body = {
                message: this.new_teacher_message,
                important: this.new_teacher_message_important,
                teacher: true,
                learning_class_id: this.learning_class_id,
            }

            this.$store.dispatch('loading', true);
            const response = await this.createLearningClassMessageRequest(request_body);
            this.$store.dispatch('loading', false);

            this.teacher_messages.push(response.data.learning_class_message);

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].create_form.success, 
                color: 'positive'
            });

            this.new_teacher_message = '';
            this.new_teacher_message_important = false;

            this.$emit('reload');
        },

        // Functions
        loadMemberMessages() {
            if (this.learning_class && this.learning_class.member_messages) {
                this.member_messages = this.learning_class.member_messages.slice(0, this.member_message_page * 10);
                if (this.member_messages.length == this.learning_class.member_messages.length) {
                    this.all_member_messages = true;
                }
            } else {
                this.all_member_messages = true;
            }
        },
        loadTeacherMessages() {
            if (this.learning_class && this.learning_class.teacher_messages) {
                this.teacher_messages = this.learning_class.teacher_messages.slice(0, this.teacher_message_page * 10);
                if (this.teacher_messages.length == this.learning_class.teacher_messages.length) {
                    this.all_teacher_messages = true;
                }
            } else {
                this.all_teacher_messages = true;
            }
        },
    },
    watch: {
        user: function() {
            this.getAbilities();
        },
        learning_class_id: function() {
            this.init();
        },
        learning_class: function() {
            this.getAbilities();
            this.loadMemberMessages();
            this.loadTeacherMessages();
        }
    },
    emits: ['reload']
}
</script>

<style lang="scss" scoped>

.button-container {
    margin-block-start: 1rem;
    justify-content: space-between;

    text-transform: uppercase;
}

.description {
    background-color: var(--whit);
    padding: 1rem;
}

.message + .message {
    margin-block-start: 0.5rem;
}

.show-older {
    margin-block-start: 1rem;
}

.switch-button {
    position: absolute;
    right: 2rem;

    cursor: pointer;

    background-color: var(--light_grey);
    border: none;
    color: var(--accent);

    font-family: var(--standard-font);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    
    text-decoration: underline;

    &:hover, &:focus-visible {
        font-weight: 400;
    }

}

@media (max-width: 40rem) {
    .button-container {
        button {
            width: 100%;
        }
    }

    .member-area,
    .teacher-area {
        position: relative;
        margin-block-start: 5rem;

        .switch-button {
            top: -2rem;
            right: 0;
        }
    }
}

</style>