const resource = {
    en: {
        description: 'Description',
        created_on: 'created on',
        by: 'by',
        messages: 'Messages',
        show_older_messages: 'show older messages',
        show_teacher_messages: 'show teacher messages',
        show_member_messages: 'show member messages',
        no_messages: 'No messages',
        create_form: {
            heading: 'Write New Message',
            input_label: 'Write new message here...',
            create: 'Create Message',
            mark_as_important: 'Mark message as important',
            success: 'message created',
        }
    },
    de: {
        description: 'Beschreibung',
        created_on: 'erstellt am',
        by: 'von',
        messages: 'Nachrichten',
        show_older_messages: 'ältere Nachrichten anzeigen',
        show_teacher_messages: 'Nachrichten der Lehrer anzeigen',
        show_member_messages: 'Nachrichten der Mitglieder anzeigen',
        no_messages: 'Keine Nachrichten',
        create_form: {
            heading: 'Neue Nachricht verfassen',
            input_label: 'Neue Nachricht...',
            create: 'Nachricht abschicken',
            mark_as_important: 'Nachricht als wichtig hervorheben',
            success: 'Nachricht erstellt',
        }
    }
}

export default resource;