<template>
    <div :class="['comment', message.important ? 'important' : '']">
        <p class="comment-label" v-html="message.author.full_name" />
        <p class="comment-description" v-html="`${resource[lang].commented_on} ${date}`" />
        <p class="comment-text" v-html="message.message" />
        <InputTextArea 
            class="text-area" 
            v-if="in_edit" 
            :label="resource[lang].updated_message" 
            v-model="updated_message" 
        />
        <div class="labels-and-actions">
            <div class="labels">
                <span v-if="message.teacher" v-html="resource[lang].only_for_teachers" />
                <span v-if="message.important" v-html="resource[lang].important" />
            </div>
            <div class="actions">
                <button v-if="!in_edit && edit" v-html="resource[lang].edit" @click="showEditField" />
                <button v-if="in_edit && delet" v-html="resource[lang].delete" @click="showDeleteModal"/>
                <button v-if="in_edit" v-html="resource[lang].discard" @click="discard" />
                <button v-if="in_edit" v-html="resource[lang].update" @click="update" />
            </div>
        </div>

        <DeleteModal
            v-if="showDeleteModalFlag"
            @confirm="deleteMessage"
            @cancel="cancelDeleteModal"
        />
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/learning-class-message-resource'

import DeleteModal from '@/components/modals/DeleteModal'
import InputTextArea from '@/components/basic/InputTextArea'

import { getAbility } from '@/assets/js/user-abilities'

export default {
    inject: ['$dateOptions'],
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    components: {
        DeleteModal,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            in_edit: false,
            updated_message: '',

            // delete modal
            showDeleteModalFlag: false,

            // user abilities
            edit: false,
            delet: false,
            resolve: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        date() {
            return new Date(this.message.updated_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.getUserAbilities();
        },
        async getUserAbilities() {
            this.edit = await getAbility('update-learning-class-message', this.user, this.message);
            this.delet = await getAbility('delete-learning-class-message', this.user, this.message);
        },

        // Actions
        showEditField() {
            this.in_edit = true;
        },
        discard() {
            this.updated_message = '';
            this.in_edit = false;
        },
        update() {
            this.$emit('updateMessage', this.message.id, this.updated_message);
            this.updated_message = '';
            this.in_edit = false;
        },
        cancelDeleteModal() {
            this.showDeleteModalFlag = false;
        },
        deleteMessage() {
            this.showDeleteModalFlag = false;
            this.$emit('deleteMessage', this.message.id);
        },
        showDeleteModal() {
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true);
        }
    },
    watch: {
        comment: function() {
            this.getUserAbilities();
        },
        user: function() {
            this.getUserAbilities();
        }
    },
    emits: ['deleteMessage', 'updateMessage']
}
</script>

<style lang="scss" scoped>

.comment {
    position: relative;
    background-color: var(--whit);
    padding: 1rem;
}

.comment-description {
    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;

    margin-bottom: 2rem;
}

.labels-and-actions {
    
    position: absolute;
    top: 1rem;
    right: 1rem;

    button, span {
        cursor: pointer;

        font-family: var(--standard-font);
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        
    }

    .labels span {
        color: var(--blak);
    }

    .actions {
        margin-block-start: 0.5rem;
    }

    .actions, .labels {
        text-align: right;
    }

    .actions button {
        background-color: var(--whit);
        border: none;
        color: var(--accent);
        text-decoration: underline;
    }

    .actions > * + * {
        margin-inline-start: 1rem;
    }

    .labels > * + * {
        margin-inline-start: 2rem;
    }
}

.actions > *:hover, .actions > *:focus-visible {
    font-weight: 400;
}

.text-area {
    margin-block-start: 1rem;
    border-top: solid 1px var(--blak);
}

.important::after {
    content: ' ';
    position: absolute;
    left: -8px;
    top: 0;

    height: 100%;
    width: 4px;
    background-color: var(--light_blue);
}

@media (max-width: 40rem) {
    .labels {
        display: none;
    }

    .actions {
        padding-block-start: 2rem;
    }

    .comment-description {
        margin-bottom: 4rem;
    }
}

</style>