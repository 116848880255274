const resource = {
    en: {
        edit: 'edit',
        delete: 'delete',
        leave_learning_class: 'leave learning class',
        show_members: 'show members',
        back_to_learning_class: 'back to learing class',

        create_learning_class: 'Create New Learning Class',

        save: 'save',
        add_user: 'add user',
        discard: 'discard',
    },
    de: {
        edit: 'bearbeiten',
        delete: 'löschen',
        leave_learning_class: 'Lernklasse verlassen',
        show_members: 'Mitglieder anzeigen',
        back_to_learning_class: 'zurück zur Lernklasse',

        create_learning_class: 'Neue Lernklasse erstellen',

        save: 'speichern',
        add_user: 'Anwender*in hinzufügen',
        discard: 'verwerfen',
    }
}

export default resource;